import React, { FC } from "react";
import {
  Box,
  Heading,
  Text,
  useColorMode,
  VStack,
  Link,
  Grid,
  Image,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import {
  DiPhp,
  DiDotnet,
  DiJavascript,
  DiDatabase,
  DiGitMerge,
} from "react-icons/di";

const Jumbotron: FC = () => {
  return (
    <Box maxW="2xl" mx="auto" px={4} py={8}>
      <Grid templateColumns="1fr">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Image
            objectFit="cover"
            objectPosition="top"
            borderRadius="full"
            boxSize="180px"
            src="/images/common/fx3costa_2024.jpeg"
            alt="Felix Costa"
          />
          <Heading
            as="h1"
            fontFamily="body"
            bgGradient="linear-gradient(to left, #45abff, #673AB7)"
            bgClip="text"
          >
            Hi, I'm Felix Costa
          </Heading>
        </Box>
        <Box textAlign="left" paddingTop="5">
          <Text>
            I've been working with software development for 10 years, graduated
            in Information Systems and currently I'm CTO and partner at{" "}
            <Link href="https://soterotech.com.br" isExternal>
              Sotero Tech
            </Link>
            , where I work as leader of development teams and software
            architect. <br />
            <br />
          </Text>
          <Text>
            I am passionate about code, quality and software architecture with
            experience in different market segments. Solving the world's
            problems and challenges through technology is one of my main
            missions. <br />
            <br />I have experience with several technologies and tools, among
            the main ones:
          </Text>
          <List spacing={3} paddingTop="5">
            <ListItem>
              <ListIcon as={DiPhp} color="#673AB7" />
              PHP: Symfony, Laravel, Zend Framework, ReactPHP, Swoole;
            </ListItem>
            <ListItem>
              <ListIcon as={DiJavascript} color="#673AB7" />
              JavaScript / TypeScript: Nodejs, React, Vuejs, Angular;
            </ListItem>
            <ListItem>
              <ListIcon as={DiDotnet} color="#673AB7" />
              Python: Flask, Django;
            </ListItem>
            <ListItem>
              <ListIcon as={DiDotnet} color="#673AB7" />
              C#: .NET, ASP.NET, Unity3D;
            </ListItem>
            <ListItem>
              <ListIcon as={DiDotnet} color="#673AB7" />
              Mobile: React Native, Xamarin, Flutter;
            </ListItem>
            <ListItem>
              <ListIcon as={DiDatabase} color="#673AB7" />
              MySQL, Postgre, SQL Server, MongoDB, ElasticSearch, Redis,
              Rabbitmq;
            </ListItem>
            <ListItem>
              <ListIcon as={DiGitMerge} color="#673AB7" />
              Docker, DevOps
            </ListItem>
          </List>
        </Box>
      </Grid>
    </Box>
  );
};

export default Jumbotron;
